<template>
    <div class="resule-page">
        <div class="resule-img"></div>
        <div class='result-text'>
            <div class="text-frist">您的借款申请已提交</div>
            <div class="text-secord">机构会在2小时内完成审核</div>
            <div class="text-frist">通过后会在1~3天内放款</div>
            
        </div>
        <div class='result-btn' @click='goSuccess'>
            完成
        </div>
        <!-- 为你推荐 -->
        <div class="i-hot-product">
            <div class='i-hot-header'>
                <div class='i-hot-right'>为你推荐</div>
            </div>
            <!-- 卡片 -->
            <div class='i-hot-card' v-for="item in productList" :key='item.prodCode'  @click="proItem(item)">
                <div class='i-hot-card-header'>
                    <div >
                    <img class="i-hot-card-img" :src="item.prodIcon" alt='产品图'/>
                    </div>
                    <div class='i-hot-card-text'>{{item.prodName}}</div>
                </div>
                <div class='i-hot-card-moeny'>
                    <div class='i-hot-card-number'>{{item.totalCreditAmt}}</div>
                    <div class='i-hot-card-btn'>立即借钱</div>
                </div>
                <div class="i-hot-card-des">
                    <div class="i-hot-card-foo" v-if='item.slogans.length==1'>{{item.slogans[0]}}</div>
                    <div class='i-hot-card-footer' v-else>
                    <div class='i-hot-footer-right'>
                        {{item.slogans[0]}}
                        <!-- <span>利率：</span>
                        <span>0.04%/日</span> -->
                    </div>
                    <div class='i-hot-footer-left'>
                        {{item.slogans[1]}}
                        <!-- <span>期限：</span>
                        <span>30-360日</span> -->
                    </div>
                    </div>
                    
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import { onMounted, toRefs,reactive } from "vue"
import {Toast} from 'vant';
import APP from '../../utils/APP'
import API from '../../server/api'
import { routerTag} from '../../utils/index'
export default ({
    name: 'Index',
    setup() {
         const state=reactive({
        
            productList:[],//产品
        })
        //还款完成
        const goSuccess=()=>{
            window.TDAPP.onEvent("申请完成页-点击完成-进入");
            //跳转到账单页
           APP.JUMP_H5(routerTag)
        }
         const proInit= async ()=>{
          const result= await API.loanList({"type":"1"})
          state.productList=result.productList
    
        }
         const proItem=(item)=>{
            window.TDAPP.onEvent("申请完成页-点击卡片-进入");
            window.localStorage.setItem('appNo',item.appNo)
            APP.JUMP_H5(item.button.url)
        }
        onMounted(()=>{
            APP.IS_SHOW_BACK(false)
            APP.SET_TITLE('申请完成')
            proInit()
        })
        return{
            ...toRefs(state),
            goSuccess,
            proItem
        }
    },
})
</script>
<style scoped lang='less' src='./index.less'></style>